import { AxiosInstance, AxiosResponse } from "axios";
import { TacticPrimary } from "@/types/tacticPrimary";
import { client } from "@/api/client";
import { Tactic } from "@/types/tactic";
import { authHeaders } from "@/hoc/authHeaders";

export async function fetchTacticPrimaries(
  url: string
): Promise<TacticPrimary[]> {
  const { data } = await client.get(url).catch((error) => {
    throw new Error(error.response.message);
  });
  return data;
}

export async function getTacticPrimary(url: string): Promise<TacticPrimary> {
  const headers = await authHeaders();
  const { data } = await client
    .get(url, {
      headers,
    })
    .catch((error) => {
      throw new Error(error.response.message);
    });
  return data;
}

export interface TacticPrimaryRepository {
  getTacticPrimary(id: string): Promise<Tactic>;
  listTacticPrimaries(): Promise<Tactic[]>;
}

export async function fetchTacticPrimary(url: string): Promise<TacticPrimary> {
  const { data } = await client.get(url).catch((error) => {
    throw new Error(error.response.message);
  });
  return data;
}

class TacticPrimaryRepositoryImpl implements TacticPrimaryRepository {
  private readonly client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  async getTacticPrimary(id: string): Promise<Tactic> {
    const response = await this.client
      .get(`/api/v1/tactic_primaries/${id}`)
      .catch((error) => {
        throw new Error(error.response.message);
      });
    const { data } = response;
    return data;
  }

  async listTacticPrimaries(): Promise<Tactic[]> {
    const { data } = await this.client.get(`/api/v1/tactic_primaries`);
    return data;
  }
}

export const newTacticPrimaryRepository = (
  client: AxiosInstance
): TacticPrimaryRepository => {
  return new TacticPrimaryRepositoryImpl(client);
};
