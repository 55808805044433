import { Tactic } from "@/types/tactic";
import { authHeaders } from "@/hoc/authHeaders";
import { client } from "@/api/client";

export async function fetchCourseGroupTactics(url: string): Promise<Tactic[]> {
  const headers = await authHeaders();
  const { data } = await client
    .get(url, {
      headers,
    })
    .catch((error) => {
      throw new Error(error.response.message);
    });
  return data;
}
