import React, { useEffect, useState } from "react";
import { AppLayout } from "@/layouts/AppLayout";
import { Head } from "@/components/base/Head";
import { AppSectionTitle } from "@/components/base/ui/AppSectionTitle";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCurrentUser } from "@/hoc/useCurrentUser";
import useSWR from "swr";
import { fetchCourseGroupTactics } from "@/repositories/courseGroupTactics";
import { Tactic } from "@/types/tactic";
import { Loading } from "@/components/base/Loading";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { fetchTacticPrimaries } from "@/repositories/tacticPrimary";
import Image from "next/image";
import { ScrollToTop } from "@/components/base/ui/ScrollToTop";
import { microCMSClient } from "@/utils/client";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import { News } from "@/types/news";
import { SectionTitle } from "@/components/base/ui/SectionTitle";

type GroupTactic = {
  name: string;
  id: string;
  items: Tactic[];
};

const MyPage = ({ news }: { news: News[] }) => {
  const META_TITLE = "マイページ";

  const { isAuthChecking, currentUser } = useCurrentUser();
  const router = useRouter();

  useEffect(() => {
    if (!isAuthChecking && !currentUser) {
      (async () => {
        await router.push("/signin");
      })();
    }
  }, [isAuthChecking, currentUser, router]);
  const [groupTactics, setGroupTactics] = useState<GroupTactic[]>([]);
  const { data: tactics } = useSWR(
    `/api/v1/course_group_tactics`,
    async (url) => fetchCourseGroupTactics(url)
  );

  const { data: tactic_primaries } = useSWR(`/api/v1/tactic_primaries`, (url) =>
    fetchTacticPrimaries(url)
  );

  const checkAllItemsAttended = (items: Tactic[]): boolean => {
    return items.every((item) => item.is_attended);
  };

  const [groupsToShow, setGroupsToShow] = useState(4);

  useEffect(() => {
    const temp: GroupTactic[] = [];
    tactics?.forEach((tactic) => {
      const target = temp.find((_) => _.id === tactic.tactic_primary.id);
      if (target) {
        target.items.push(tactic);
        target.items.sort((a, b) => a.sort_order - b.sort_order);
      } else {
        temp.push({
          name: tactic.tactic_primary.name_ja,
          id: tactic.tactic_primary.id,
          items: [tactic],
        });
      }
    });
    setGroupTactics(temp);
  }, [tactics]);

  const findImageById = (id: string) => {
    if (tactic_primaries) {
      const tactic_primary = tactic_primaries.find(
        (tactic_primary) => tactic_primary.id === id
      );
      if (tactic_primary) return tactic_primary.image;
    }
  };

  return (
    <>
      <Head title={META_TITLE} />
      <div className={"mb-10"}>
        <SectionTitle title={"マイページTOP"} isBorder={false} />
      </div>
      <main className={"pb-10"}>
        <section className={"mb-14 md:w-11/12 m-auto"}>
          <div
            className={
              "md:flex flex-1 overflow-hidden mb-8 border-4 border-indigo-50 rounded-lg"
            }
          >
            <h2
              className={
                "flex items-center text-lg font-bold md:px-10 pr-3 mr-3"
              }
            >
              <span
                className={
                  "leading-[1.75] text-sm md:text-base mb-3 md:mb-0 text-orange-500"
                }
              >
                お知らせ
              </span>
            </h2>
            <div className={"space-y-2 flex-1 p-5"}>
              {news.map((_news, index) => (
                <div key={index}>
                  <Link
                    href={`/news/${_news.id}`}
                    className={"flex md:items-center md:px-5"}
                  >
                    <span className={"md:mr-5 mr-1 text-sm w-[100px]"}>
                      {format(new Date(_news.publishedAt), "Y/MM/dd", {
                        locale: ja,
                      })}
                    </span>
                    <p
                      className={
                        "md:text-base text-sm flex-1 font-bold text-indigo-700 hover:text-fuchsia-700"
                      }
                    >
                      {_news.title}
                    </p>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className={"mb-14 md:w-11/12 m-auto"}>
          <div className={"mb-4"}>
            <AppSectionTitle title={"勉強会・セミナー予定"} />
          </div>
          <GoogleCalendarEmbed />
        </section>

        <section className={"mb-14 md:w-11/12 m-auto"}>
          <div className={"mb-4"}>
            <AppSectionTitle title={"未受講コンテンツ"} />
          </div>
          {tactics ? (
            <>
              {groupTactics
                .slice(0, groupsToShow)
                .map((groupTactic: GroupTactic) => {
                  if (checkAllItemsAttended(groupTactic.items)) {
                    return null;
                  }
                  return (
                    <div
                      key={groupTactic.id}
                      className={"border border-gray-200 mb-5 rounded"}
                    >
                      <div className={"flex items-center p-4 border-b"}>
                        {tactic_primaries && findImageById(groupTactic.id) && (
                          <Image
                            src={findImageById(groupTactic.id) || ""}
                            alt={""}
                            width={60}
                            height={60}
                            className={"mr-3"}
                          />
                        )}
                        <p>{groupTactic.name}</p>
                      </div>
                      <div className={"md:p-4 py-2"}>
                        {groupTactic.items.map((tactic, itemIndex) => (
                          <div key={itemIndex}>
                            <Link
                              href={`/app/course_group_tactics/${tactic.id}`}
                              className={
                                "flex items-center justify-between p-4 hover:opacity-60 duration-300"
                              }
                            >
                              <div className={"flex items-center flex-1"}>
                                {tactic.is_attended ? (
                                  <CheckCircleIcon
                                    className={"fill-green-400 w-6 mr-2"}
                                  />
                                ) : (
                                  <CheckCircleIcon
                                    className={"fill-gray-200 w-6 mr-2"}
                                  />
                                )}
                                <p className={"flex-1 pr-2"}>
                                  【第{tactic.sort_order}回】 {tactic.title}
                                </p>
                              </div>
                              <ArrowRightIcon className={"w-4"} />
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                })}
              {groupTactics.length > groupsToShow && (
                <div className="flex justify-center mt-3">
                  <button
                    onClick={() => setGroupsToShow(groupsToShow + 100)}
                    className="false inline-flex items-center rounded-md border border-transparent bg-blue-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    もっと見る ▼
                  </button>
                </div>
              )}
            </>
          ) : (
            <Loading />
          )}
        </section>
        <ScrollToTop />
      </main>
    </>
  );
};

export async function getStaticProps() {
  try {
    const [news] = await Promise.all([
      microCMSClient.get({
        endpoint: "news",
        queries: {
          limit: 4,
        },
      }),
    ]);

    if (!news || !Array.isArray(news.contents)) {
      throw new Error("Invalid data format");
    }

    return {
      props: {
        news: news.contents,
      },
    };
  } catch (error) {
    console.error(error);
    return {
      notFound: true,
    };
  }
}

const GoogleCalendarEmbed = () => {
  return (
    <iframe
      src="https://calendar.google.com/calendar/embed?wkst=2&bgcolor=%23ffffff&ctz=Asia%2FTokyo&showTitle=0&showNav=1&showDate=1&showTabs=0&showTz=0&showCalendars=0&src=Y180NjBiMzU2ZTRhMjU1OWMxNDA2ZmNlNTk5M2M5YTI5ODM4MjdjNzdjOTJjZGMzNGEzM2RiMzNlN2U0MTBjMWE1QGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&src=amEuamFwYW5lc2UjaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23F4511E&color=%23F4511E"
      height="450"
      scrolling="no"
      className="m-auto md:w-11/12 w-full"
    />
  );
};

MyPage.Layout = AppLayout;
export default MyPage;
