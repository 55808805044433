import Link from "next/link";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import React from "react";

export const SectionTitle = ({
  title,
  isBorder = true,
}: {
  title: string | undefined;
  isBorder?: boolean;
}) => {
  return (
    <div
      className={`${
        isBorder && "border-t border-gray-400 pt-3"
      } flex justify-between`}
    >
      <h1 className={"md:text-2xl text-xl"}>{title}</h1>
    </div>
  );
};
