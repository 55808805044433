import Link from "next/link";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import React from "react";

export const AppSectionTitle = ({
  title,
  indexURL,
}: {
  title: string;
  indexURL?: string;
}) => {
  return (
    <div className={`flex justify-between`}>
      <h1 className={"text-xl font-bold"}>
        <span className={"leading-[1.75]"}>{title}</span>
      </h1>
      {indexURL && (
        <Link
          href={indexURL}
          className={
            "flex items-center text-blue-700 font-bold hover:opacity-60 duration-300"
          }
        >
          一覧へ
          <ArrowRightIcon className={"w-5 ml-1"} />
        </Link>
      )}
    </div>
  );
};
